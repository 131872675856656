import React from 'react';
import styled from 'styled-components';
import ImageRefresh from '../assets/img/refresh-1.svg';
import StyledButton from './StyledButton';

const Button = styled(StyledButton)`
      position: fixed;
    /* left: 3rem; */
    bottom: 2rem;
    background-image: url(/static/media/refresh.6504d2b5.svg);
    /* margin: 0 auto; */
    width: 3.5rem;
    height: 3.5rem;
    /* background: #3497fd; */
    border-radius: 8px;
    color: #ffffff;
    /* display: block; */
    margin: 0 auto;
    left: 0;
    right: 0;
    /* top: 0; */
    background-size: 3rem!important;
  background-image: url(${ImageRefresh});
`;
export default function RefreshButton({ visible = false, handleUpdate }) {
  return <Button className={visible ? 'visible' : 'hidden'} onClick={handleUpdate}></Button>;
}
